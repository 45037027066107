var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "not-found-image-container scroll-x" },
    [
      _c("v-img", {
        staticClass: "mx-auto not-found-image",
        attrs: { src: require("@/assets/errors/404.png") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }